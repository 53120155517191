@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500&display=swap');

body {
  margin: 0;
  font-family: "'Montserrat', sans-serif";
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import "~react-image-gallery/styles/css/image-gallery.css";

/* .image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(100vh);
} */
